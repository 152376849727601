<template>
  <v-app>
    <PreorderNavbar :isSimia="isSimiaroom"></PreorderNavbar>
    <v-content>
      <v-container align="center" justify="center">

<!--        <v-fade-transition>-->
<!--          <div v-if="loading">-->
<!--            <v-dialog v-model="localTrue" max-width="500" persistent>-->
<!--              <v-card>-->
<!--                <v-card-text class="pt-5">-->
<!--                  <v-progress-linear-->
<!--                      active-->
<!--                      indeterminate-->
<!--                      query-->
<!--                      rounded-->
<!--                      stream-->
<!--                  ></v-progress-linear>-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--            </v-dialog>-->
<!--          </div>-->
<!--        </v-fade-transition>-->


        <v-alert type="warning" dense>
         {{$t('$vuetify.preOrderPage.hint')}}
        </v-alert>

        <v-row justify="center" align="center" dense>
          <v-col cols="12">
            <v-card class="elevation-1">
              <v-card-title class="font-weight-bold ">
                <span>{{$t('$vuetify.preOrderPage.titleBox')}}</span>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text>
                <div dense>
                  <p class="pa-0 ma-0 pr-4">
                    <v-list-item-icon class="mx-1">

                      <v-icon x-small> fas fa-circle</v-icon>
                    </v-list-item-icon>

                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[0]')}}

                    <template v-if="isSimiaroom">
                      {{$t('$vuetify.preOrderPage.mainPreorderOptions[3]')}}
                    </template>

                  </p>
                  <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">
                    <v-list-item-icon class="mx-1">
                      <v-icon x-small> fas fa-circle</v-icon>
                    </v-list-item-icon>
                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[2]')}}

                  </p>

                  <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">
                    <v-list-item-icon class="mx-1">
                      <v-icon x-small> fas fa-circle</v-icon>
                    </v-list-item-icon>
                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[4]')}}
                    (
                    <a class="mx-1 doctor-links" target="_blank"
                       href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d9%85%d9%87%d8%af%db%8c-%d8%a8%db%8c%d9%86%d8%a7/">
                      {{$t('$vuetify.preOrderPage.doctors.bina')}} </a>
                    ،
                    <a class="mx-1 doctor-links" target="_blank"
                       href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d8%b3%d8%a7%d9%85%d8%b1%d9%86%d8%af-%d8%b3%d9%84%db%8c%d9%85%db%8c/">
                      {{$t('$vuetify.preOrderPage.doctors.salimi')}} </a>
                    و
                    <a class="mx-1 doctor-links" target="_blank"
                       href="https://simiaroom.com/counselor/%d8%af%da%a9%d8%aa%d8%b1-%d8%ac%d8%b9%d9%81%d8%b1-%d8%a8%d9%88%d8%a7%d9%84%d9%87%d8%b1%db%8c/">
                      {{$t('$vuetify.preOrderPage.doctors.bolhari')}}
                    </a>
                    )  {{$t('$vuetify.preOrderPage.mainPreorderOptions[5]')}}

                  </p>


                  <p v-if="currency=='dollar'">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon x-small class="green--text"> fas fa-circle</v-icon>
                    </v-list-item-icon>

                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[1]')}}
                  </p>
                  <p v-if="isSimiaroom" class="pa-0 ma-0 pr-4">
                    <v-list-item-icon class="mx-1">
                      <v-icon x-small class="green--text"> fas fa-circle</v-icon>
                    </v-list-item-icon>

                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[6]')}}
                    {{discountText}}
                    {{$t('$vuetify.preOrderPage.mainPreorderOptions[7]')}}



                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" dense>

          <v-col cols="12" :lg="loggedIn? 12 : 8">
            <v-card class="pb-2 pa-0 elevation-0">
              <CartComponent
                  @finishedSendingOrder="sendingOrder = false"
                  discount="true"
                  price="true"
                  preorder="true"
                  showLoading="false"
                  :preorderCoupon="couponCode"
              ></CartComponent>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-card class="pa-3" v-if="!loggedIn">
              <!--<template v-if="action == null">-->
              <!--<v-card class="elevation-0" :loading="checkingEmail">-->
              <!--<v-card-title>ایمیل خود را وارد کنید</v-card-title>-->
              <!--<v-form ref="registerForm" @submit.prevent="checkUserStatus">-->
              <!--<v-card-text>-->
              <!--<v-text-field-->
              <!--v-model="email"-->
              <!--outlined-->
              <!--type="text"-->
              <!--label="ایمیل"-->
              <!--required-->
              <!--:rules="emailRules"-->
              <!--ref="signUpLoginForm"-->
              <!--&gt;-->
              <!--</v-text-field>-->
              <!--</v-card-text>-->
              <!--</v-form>-->
              <!--<v-card-actions class="justify-center mb-3">-->
              <!--<v-btn color="primary" @click.stop.prevent="checkUserStatus"-->
              <!--:disabled="checkingEmail">بررسی ایمیل-->
              <!--</v-btn>-->
              <!--</v-card-actions>-->
              <!--</v-card>-->
              <!--</template>-->
              <!--<template-->
              <!--v-else-if="action != null"-->
              <!--&gt;-->

              <template
              >

                <v-tabs centered
                        v-model="tab"
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab key="1" ref="loginTab" href="#login">
                    {{$vuetify.lang.t('$vuetify.loginLabel')}}
                  </v-tab>
                  <v-tab key="2" ref="signUpTab" href="#register">
                    {{$vuetify.lang.t('$vuetify.signUpLabel')}}
                  </v-tab>

                  <v-tab-item
                      :value="'login'"
                  >
                    <v-card
                        flat
                        tile
                    >

                      <LoginComponent :email="email"></LoginComponent>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                      :value="'register'"
                  >
                    <v-card
                        flat
                        tile
                    >
                      <SignupComponent @userLoggedInAfterRegister="userLoggedIn"
                                       :email="email"></SignupComponent>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </template>

            </v-card>
            <!--<v-card -->
            <!--class="secondary&#45;&#45;text d-flex justify-center align-center pa-5">-->
            <!--شما با موفقیت وارد شدید-->
            <!--</v-card>-->
          </v-col>

        </v-row>
      </v-container>
      <v-snackbar

          v-model="snackbar.visibility"
          :color="snackbar.color"
          :bottom="snackbar.position === 'bottom'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
      >
        {{snackbar.message}}
        <v-btn
            color="primary--text"
            @click="snackbar.visibility = false"
            fab
        >
          <v-icon small color="red">fas fa-times</v-icon>
        </v-btn>
      </v-snackbar>
      <PreOrderFooter :isSimiaroom="isSimiaroom"></PreOrderFooter>

    </v-content>

  </v-app>
</template>

<script>

/*eslint-disable*/
import PreorderNavbar from '@/components/PreorderNavbar.vue';
import PreOrderFooter from '@/components/PreOrderFooter.vue';

import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import CartComponent from "./cart/CartComponent";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";

import {getCookie} from "../utilities";

export default {
  components: {
    CartComponent,
    PreorderNavbar,
    PreOrderFooter,
    LoginComponent,
    SignupComponent,

  },
  computed: {
    discountText() {
      return this.currency == 'rial' ? '50,000 تومان' : '5 دلار';
    },
    ...mapGetters({
      'loading': 'loading',
      'loggedIn': 'userIsLoggedIn',
      'platform': 'getPlatform',
      'currency': 'getUserCurrency'
    })
  },
  data() {
    return {
      localTrue:true,
      isSimiaroom: false,
      hasCouponCode: false,
      couponCode: null,
      showOfflinePaymentDialog: false,
      validating: false,
      checkingEmail: false,
      sendingOrder: false,
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 3000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ],
      action: null,
      state: null,

      window: 1,
      tab: 'register',
      email: ''
    }
  },

  mounted() {
      EventBus.$on('notify', this.showSnackbar);
    // this.validatePackage();
  },
  created() {

    let inviter = this.getCookie('inviter');
    if (this.$route.query.hasOwnProperty('inviter')) {
      this.delete_cookie('inviter', '/', 'simiaroom.com');
      this.$store.dispatch('setInviter', this.$route.query.inviter);
    } else if (inviter != "") {
      this.$store.dispatch('setInviter', inviter);
    }


    this.$store.dispatch('getPlatformInfo');
    EventBus.$on('setLoading', this.setLoading);

    if (this.$route.query.coupon_code != undefined) {
      this.hasCouponCode = true;
      // this.couponCode = this.$route.query.coupon_code;
    }


    this.$store.dispatch('checkLogin').then(() => {
      this.validatePackage();
    }).catch(() => {

    });

    if (window.origin.includes('simiaroom')) {
      this.isSimiaroom = true;
    } else {
      this.isSimiaroom = false;
    }

    // this.$store.dispatch('getPlatformInfo').then(() => {
    //     document.title = this.platform.name;
    // if (this.platform.name === 'simiaroom') {
    // this.isSimiaroom = true;
    // }
    // });
    // this.validatePackage();

    // if (this.loggedIn) {
    //     this.$store.dispatch('getCart');
    // }

  },
  beforeDestroy() {
    EventBus.$off('setLoading', this.setLoading);
    EventBus.$off('notify', this.showSnackbar);
  },
  methods: {
    delete_cookie(name, path, domain) {
      if (this.getCookie(name) != "") {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setLoading(loading) {
      this.$store.commit('setLoading', loading);
    },
    showSnackbar(color = 'success', timeout = 3000, message = 'عملیات با موفقیت انجام شد.') {
      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

    },
    checkUserStatus() {
      if (this.$refs.registerForm.validate()) {
        this.checkingEmail = true;
        this.$store.dispatch('checkUserAvailability', {email: this.email}).then((resp) => {
          this.action = resp.data.action;
          this.tab = this.action;
        }).finally(() => {
          this.checkingEmail = false;
        });
      }
    },
    validatePackage() {
      // this.loading = true;
      EventBus.$emit('setLoading', true);
      let item = {
        id: this.$route.params.orderId,
        preorder: true
      };
      this.validating = true;

      this.$store.dispatch('addToCart', item).then(() => {
        this.validating = false;

        if (this.hasCouponCode) {
          this.couponCode = this.$route.query.coupon_code;
        }
      }).catch(() => {
        this.validating = false;
        setTimeout(() => {
          this.$router.push({name: 'login', params: {lang: 'fa'}});
        }, 3000)

      }).finally(() => {
        this.validating = false;
        EventBus.$emit('setLoading', false);
        // this.loading = false;
      });

    },
    // sendOrder() {
    //     this.sendingOrder = true;
    //     EventBus.$emit('sendOrderEvent');
    // },
    userLoggedIn(newUser) {

      let user = {
        email: newUser.email,
        password: newUser.password
      };

      this.$store.dispatch("login", user).then(() => {
        this.$store.dispatch('getUserInfo').then(() => {
          this.$store.commit('setLoggedIn');
          EventBus.$emit('userLoggedIn');
        })
      })

    },
  }
}
</script>

<style scoped>

.doctor-links {
  width: 100px;
}
</style>